import * as React from 'react';
import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Ticker from 'framer-motion-ticker';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Windows } from 'styled-icons/boxicons-logos';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import { motion } from "framer-motion"

const lSourceImgs = [
  { 
    src: "../static/images/carousel/LoggersDugoutcxl.jpg",
    width: 1166,
    height: 518,
  },
  { 
    src: "../static/images/carousel/LoggersImage7cxl.jpg",
    width: 1381,
    height: 1032,
  },
  { 
    src: "../static/images/carousel/LoggersImage18cxl.jpg", 
    width: 962,
    height: 976,
  },
  { 
    src: "../static/images/carousel/LoggersImage8cxl.jpg", 
    width: 1171,
    height: 1080,
  },
  { 
    src: "../static/images/carousel/LoggersImage21cxl.jpg", 
    width: 1334,
    height: 1013,
  },
  { 
    src: "../static/images/carousel/Loggers2023cxl.jpg", 
    width: 1119,
    height: 933,
  },
];

const sSourceImgs = [
  { 
    src: "../static/images/carousel/LoggersDugoutcl.jpg",
    width: 810,
    height: 360,
  },
  { 
    src: "../static/images/carousel/LoggersImage7cl.jpg",
    width: 463,
    height: 360,
  },
  { 
    src: "../static/images/carousel/LoggersImage18cl.jpg", 
    width: 321,
    height: 360,
  },
  { 
    src: "../static/images/carousel/LoggersImage8cl.jpg", 
    width: 394,
    height: 360,
  },
  { 
    src: "../static/images/carousel/LoggersImage21cl.jpg", 
    width: 540,
    height: 360,
  },
  { 
    src: "../static/images/carousel/Loggers2023cl.jpg", 
    width: 279,
    height: 360,
  },
];

export default function LoggersCarousel() {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'));
  const [viewportHeight, setViewportHeight] = useState(Math.max(document.documentElement.clientHeight-60,180));
  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);

  
  useEffect(() => {
    function handleResize() {
      if (isMd) {
        setViewportHeight(window.innerHeight-60);
      }
      setViewportWidth(window.innerWidth);
    }

    let debounce = (callback, delay) => {
      let myTimeout;
      return () => {
        clearTimeout(myTimeout);
        myTimeout = setTimeout(() => {
          callback()
        }, delay);
      };
    };
    
    let doDebounce = debounce(() => handleResize(), 500)

    window.addEventListener('resize', () => doDebounce());

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Box>
      { isMd &&
        <Ticker duration={60} isPlaying='true' direction='-1' >
          {lSourceImgs.map((sourceImg, index) => (
            <Box
              component='img'
              key={index}
              src={sourceImg.src}
              alt="Ticker Image"
              width= {sourceImg.width*viewportHeight/sourceImg.height}
              height= {viewportHeight}
            />
          ))}
        </Ticker>
      }
      { !isMd &&
        <Ticker duration={60} isPlaying='true' direction='-1' >
          {sSourceImgs.map((sourceImg, index) => (
            <Box
              component='img'
              key={index}
              src={sourceImg.src}
              alt="Ticker Image"
              width= {sourceImg.width*(viewportHeight/2.5)/sourceImg.height}
              height= {viewportHeight/2.5}
            />
          ))}
        </Ticker>
      }

      { isMd &&
        <Box
          component="img"
          src="../static/images/Loggers v1 halo 2.svg"
          alt="Halo"
          width='auto'
          height={viewportHeight/2}
          sx={{
            border: 0,
            borderColor: 'white',
            position: 'absolute',
            left: '50%',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            top: viewportHeight/2,
            zIndex: 1, // Render the badge on top of potential ripples.
          }}
        />
      }
      { isMd &&
        <motion.div
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1.0 }}
          transition=
          {{ 
            type: "spring",
            damping: 20,
            mass: 1.5,
            stiffness: 500,
            delay: 1,
          }}
          style={{
            position: 'absolute',
            top: viewportHeight - 60,
            left: (viewportWidth/2)-25,
          }}
        >
          <ExpandCircleDownIcon
            color='primary'
            sx={{
              fontSize: 50,
              top: viewportHeight - 60,
              left: (viewportWidth/2)-25,
            }}
          />
        </motion.div>
      }
      { !isMd &&
        <Box
          component="img"
          src="../static/images/Loggers v1 halo 2.svg"
          alt="Halo"
          width='auto'
          height={viewportHeight/4}
          sx={{
            border: 0,
            borderColor: 'white',
            position: 'absolute',
            left: '50%',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            top: 50 + viewportHeight/5,
            zIndex: 1, // Render the badge on top of potential ripples.
          }}
        />
      }
    </Box>
  );
}

/*              backgroundColor: '#FFFFFFBF',
              boxShadow: '0px 0px 30px 30px #FFFFFFBF',
*/
/*
            <ExpandCircleDownIcon
              color='primary'
              sx={{
                fontSize: 50,
                position: 'absolute',
                top: viewportHeight - 60,
                left: (viewportWidth/2)-25,
              }}
            />
*/