
export function getSchedule() {
  return [
    {
      homeAway: 'away',
      opponent: 'Baseball 365',
      date: Date.parse('2024-05-12T00:00:00.000-05:00'),
      time: '3:00 PM',
    },
    {
      homeAway: 'away',
      opponent: 'Twin City Saints',
      date: Date.parse('2024-05-17T00:00:00.000-05:00'),
      time: '6:00 PM',
    },
    {
      homeAway: 'away',
      opponent: 'Shakopee Indians',
      date: Date.parse('2024-05-19T00:00:00.000-05:00'),
      result: 'L 0-3',
    },
    {
      homeAway: 'away',
      opponent: 'Minneapolis NorthStar',
      date: Date.parse('2024-05-27T00:00:00.000-05:00'),
      result: 'W 12-6',
    },
    {
      homeAway: 'away',
      opponent: 'Minneapolis NorthStar',
      date: Date.parse('2024-05-29T00:00:00.000-05:00'),
      result: 'W 15-6',
    },
    {
      homeAway: 'home',
      opponent: 'North St. Paul Snowmen',
      date: Date.parse('2024-06-01T00:00:00.000-05:00'),
      result: 'L 0-12',
    },
    {
      homeAway: 'home',
      opponent: 'White Bear Township',
      date: Date.parse('2024-06-02T00:00:00.000-05:00'),
      result: 'NA',
    },
    {
      homeAway: 'away',
      opponent: 'Highland Park',
      date: Date.parse('2024-06-03T00:00:00.000-05:00'),
      result: 'L 2-5',
    },
    {
      homeAway: 'home',
      opponent: 'Plymouth Hit Dawg',
      date: Date.parse('2024-06-08T00:00:00.000-05:00'),
      result: 'L 3-16',
    },
    {
      homeAway: 'away',
      opponent: 'Minneapolis NorthStar',
      date: Date.parse('2024-06-09T00:00:00.000-05:00'),
      result: 'W 12-2',
    },
    {
      homeAway: 'home',
      opponent: 'Ada Athletics',
      date: Date.parse('2024-06-09T00:00:00.000-05:00'),
      result: 'W 10-9',
    },
    {
      date: Date.parse('2024-06-14T00:00:00.000-05:00'),
      homeAway: 'away',
      opponent: "Stockmen's Irish",
      result: 'L 4-7',
    },
    {
      date: Date.parse('2024-06-16T00:00:00.000-05:00'),
      homeAway: 'away',
      opponent: "Centennial Cougars",
      result: 'L 5-7',
    },
    {
      date: Date.parse('2024-06-22T00:00:00.000-05:00'),
      homeAway: 'away',
      opponent: "St. Anthony Hogs",
      result: 'NA',
    },
    {
      date: Date.parse('2024-06-23T00:00:00.000-05:00'),
      homeAway: 'away',
      opponent: "Metro Knights",
      result: 'W 10-6',
    },
    {
      date: Date.parse('2024-06-26T00:00:00.000-05:00'),
      homeAway: 'away',
      opponent: "River Falls Fighting Fish",
      result: 'W 8-7',
    },
    {
      date: Date.parse('2024-06-27T00:00:00.000-05:00'),
      homeAway: 'home',
      opponent: "Stockmen's Irish",
      result: 'L 6-16',
    },
    {
      date: Date.parse('2024-06-29T00:00:00.000-05:00'),
      homeAway: 'away',
      opponent: "Minnetonka Monarchs",
      result: 'W 7-5',
    },
    {
      date: Date.parse('2024-06-30T00:00:00.000-05:00'),
      homeAway: 'home',
      opponent: "Minneapolis Cobras",
      result: 'NA',
    },
    {
      date: Date.parse('2024-07-07T00:00:00.000-05:00'),
      homeAway: 'away',
      opponent: "Hudson River Rats",
      result: 'L 3-7',
    },
    {
      date: Date.parse('2024-07-10T00:00:00.000-05:00'),
      homeAway: 'home',
      opponent: "Macstrength Grizzlies",
      result: 'W 10-2',
    },
    {
      date: Date.parse('2024-07-11T00:00:00.000-05:00'),
      homeAway: 'home',
      opponent: "St. Anthony Hogs",
      result: 'W 7-2',
    },
    {
      date: Date.parse('2024-07-14T00:00:00.000-05:00'),
      homeAway: 'home',
      opponent: "Metro Knights",
      time: '4:00 PM',
      locationTitle: 'Stillwater High School',
      location: '5701 Stillwater Blvd, Stillwater',
      locationUrl: 'https://maps.app.goo.gl/oJvKvLHhezwmv9th6',
    },
    {
      date: Date.parse('2024-07-16T00:00:00.000-05:00'),
      homeAway: 'away',
      opponent: "White Bear Township",
      time: '6:00 PM',
      locationTitle: 'Polar Lakes Park',
      location: '1280 Hammond Rd, White Bear Lake',
      locationUrl: 'https://maps.app.goo.gl/1S5cA3ZGTt1RTBpWA'
    },
    {
      date: Date.parse('2024-07-18T00:00:00.000-05:00'),
      homeAway: 'home',
      opponent: "Hudson River Rats",
      time: '5:30 PM',
      locationTitle: 'Stillwater High School',
      location: '5701 Stillwater Blvd, Stillwater',
      locationUrl: 'https://maps.app.goo.gl/oJvKvLHhezwmv9th6',
    },
    {
      date: Date.parse('2024-07-20T00:00:00.000-05:00'),
      homeAway: 'away',
      opponent: "Baseball 365",
      time: '3:00 PM',
      locationTitle: 'Eden Prairie High School',
      location: '17185 Valley View Rd, Eden Prairie',
      locationUrl: 'https://maps.app.goo.gl/FbDYYzYMGPrHzWgv8',
    },
    {
      date: Date.parse('2024-07-21T00:00:00.000-05:00'),
      homeAway: 'home',
      opponent: "Minneapolis NorthStar",
      time: '4:30 PM',
      locationTitle: 'Stillwater High School',
      location: '5701 Stillwater Blvd, Stillwater',
      locationUrl: 'https://maps.app.goo.gl/oJvKvLHhezwmv9th6',
    },
    {
      date: Date.parse('2024-07-24T00:00:00.000-05:00'),
      homeAway: 'away',
      opponent: "North St. Paul Snowmen",
      time: '7:00 PM',
      locationTitle: 'TBD',
    },
    {
      date: Date.parse('2024-07-28T00:00:00.000-05:00'),
      homeAway: 'away',
      opponent: "St. Anthony Hogs",
      time: '2:00 PM',
      locationTitle: 'St. Anthony High School',
      location: '3303 33rd Ave NE, St Anthony',
    },
    {
      date: Date.parse('2024-07-29T00:00:00.000-05:00'),
      homeAway: 'away',
      opponent: "Coon Rapids Redbirds",
      time: '7:30 PM',
      locationTitle: 'Wintercrest Park',
      location: '10300 Woodcrest Dr. NW',
    },
    {
      date: Date.parse('2024-08-01T00:00:00.000-05:00'),
      homeAway: 'home',
      opponent: "Metro Knights",
      time: '7:00 PM',
      locationTitle: 'McGuire Field',
    },
    {
      date: Date.parse('2024-08-02T00:00:00.000-05:00'),
      homeAway: 'away',
      opponent: "Metro Knights",
      time: '7:30 PM',
      locationTitle: 'Polar',
    },
    {
      date: Date.parse('2024-08-18T00:00:00.000-05:00'),
      homeAway: 'home',
      opponent: "Burnsville Bobcats",
      time: '1:30 PM',
      locationTitle: 'Joe Schleper Stadium',
      location: '1490 Adams St S, Shakopee',
    },
  ];
}