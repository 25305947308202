import * as React from 'react';
import Box from '@mui/material/Box';
import SectionContainer from '../mdl-components/MdlSectionContainer';
import PageTitle from './PageTitle';
import GoToTop from './GoToTop';
import { motion } from "framer-motion"

export default function Schedule() {
  return (
    <SectionContainer
      id="Schedule"
      sectionTitle="2024 Schedule"
      shade="light"
    >
      <PageTitle title='Schedule' />
      <Box
        component={motion.img}
        src="../static/images/schedule.jpg"
        alt="Schedule"
        maxWidth= {{ xs:'100%', md:'75%' }}
        viewport={{ once: true, amount: 0.25 }}
        initial={{ opacity: 0, y: 25 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ ease: 'easeOut', duration: 0.4, delay: 0.1 }}
        />
      <GoToTop />
    </SectionContainer>
  );
}
