import * as React from 'react';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import SectionContainer from '../mdl-components/MdlSectionContainer';
import { NavLink } from 'react-router-dom';
import PageTitle from './PageTitle';
import GoToTop from './GoToTop';
import '@fontsource/krona-one';
import { motion } from "framer-motion"
import { styled } from '@mui/material/styles';

const sponsors = [
  {
    imgSrc: "../static/images/StillwaterMotors1.png",
    altText: "Stillwater Motors Logo",
    caption: "Stillwater Motors",
    url: "https://www.cornerstonechevroletstillwater.com/",
  },
  {
    imgSrc: "../static/images/SpineAndSport1.png",
    altText: "Spine and Sport Chiropractic Logo",
    caption: "Spine & Sport Chiropractic",
    url: "https://www.kranzchiropractic.com/",
  },
  {
    imgSrc: "../static/images/Cozzies.png",
    altText: "Cozzie's Logo",
    caption: "Cozzie's Tavern & Grill",
    url: "http://www.cozziestavern.com/",
  },
]

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.background.dark,
  color: theme.palette.primary.contrastText,
  ':hover': {
      backgroundColor: theme.palette.background.medium,
  },
}));


export default function Sponsors({includePageTitle}) {
  return (
    <div>
      <SectionContainer
        id="sponsors"
        sectionTitle="Our Sponsors"
        shade="dark"
      >
        { includePageTitle &&
          <PageTitle title='Our Sponsors' />
        }
        <Box sx={{ width: { xs: '90%', md: '70%' } }}>
          <Grid container spacing={2} justifyContent="center" columns={{xs: 1, md: 3}}>
            {sponsors.map((sponsor, index) => (

              <Grid item xs={1} >
                <motion.div
                  viewport={{ once: true, amount: 0.25 }}
                  initial={{ opacity: 0, x: -25}}
                  whileInView={{ opacity: 1, x: 0 }}
                  transition={{ ease: 'easeOut', duration: 0.4, delay: 0.1*(index + 1) }}
                >
                  <Card 
                    elevation='4'
                    sx={{ 
                      m: '4',
                      display: 'flex', 
                      justifyContent: 'center',
                    }}
                  >
                    <CardActionArea href={sponsor.url} sx={{justifyContent: 'center', display: 'flex'}}>
                      <img
                        src={sponsor.imgSrc}
                        alt={sponsor.altText}
                        style={{
                          maxWidth: '100%',
                          height: '150px',
                        }}
                      />
                    </CardActionArea>
                  </Card>
                </motion.div>
              </Grid>
            ))}
          </Grid>
        </Box>
      </SectionContainer>
      <SectionContainer
        id="sponsors"
        sectionTitle="Fundraiser"
        shade="light"
      >
        <Typography
            variant="h6"
            gutterBottom
            component={motion.div}
            viewport={{ once: true, amount: 0.25 }}
            initial={{ opacity: 0, y: 25}}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ ease: 'easeOut', duration: 0.4, delay: 0.1 }}
            sx={{ color: 'text.primary', maxWidth: { xs: '90%', md: '60%' }, textAlign: 'justify', fontWeight: '400' }}
          >
          Every year since Jay Haws has been owner, the Loggers have hosted a charity scramble golf tournament at a local course, Loggers Trail. The majority of fundraising occurs at this tournament as it has grown from 32 players in the first year to 85 in the most recent.
          <br/> <br /> 
        </Typography>
        <motion.div
          viewport={{ once: true, amount: 0.25 }}
          initial={{ opacity: 0, x: -25}}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ ease: 'easeOut', duration: 0.4, delay: 0.2 }}
        >
          <Card 
            elevation='4'
            sx={{ 
              display: 'flex', 
              maxWidth: '100%',
              width: { xs: '90%', md: '100%' },
              justifyContent: 'center',
            }}
          >
            <CardActionArea href='https://www.loggerstrail.com/' sx={{justifyContent: 'center', display: 'flex', px: 5}}>
                <img
                  src="../static/images/loggerstrail.png"
                  alt="Loggers Trail"
                  style={{
                    maxWidth: '100%',
                    height: '150px',
                  }}
                />
            </CardActionArea>
          </Card>
        </motion.div>
      </SectionContainer>
      <SectionContainer
        id="sponsors"
        sectionTitle="Join Us"
        shade="primary"
        >
          <Typography
            variant="h6"
            gutterBottom
            sx={{ color: 'primary.contrastText', maxWidth: { xs: '90%', md: '60%' }, textAlign: 'justify', fontWeight: 400 }}
            component={motion.div}
            viewport={{ once: true, amount: 0.25 }}
            initial={{ opacity: 0, y: 25}}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ ease: 'easeOut', duration: 0.4, delay: 0.1 }}
          >
            We are always open to adding more sponsors! Our mission is to build community through baseball by keeping our roster and sponsors local to the historic baseball town of Stillwater. By becoming a member of our team, we grow the community aspect by bringing fans to businesses and customers to ballgames.
            <br/> <br /> 
          </Typography>
          <motion.div
            viewport={{ once: true, amount: 0.25 }}
            initial={{ opacity: 0, y: 25}}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ ease: 'easeOut', duration: 0.4, delay: 0.2 }}
          >
            <StyledButton 
              color="secondary"
              component={NavLink}
              variant="outlined"
              sx={{ display: 'flex' }}
              to='/contact'
              size='large'
            >
              Contact Us
            </StyledButton>
          </motion.div>

        </SectionContainer>
        <GoToTop />
      </div>
  );
}
