import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { styled, } from '@mui/material/styles';
import { motion } from "framer-motion"
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import '@fontsource/montserrat/100.css';
import '@fontsource/montserrat/300.css';
import '@fontsource/montserrat/400.css';
import '@fontsource/montserrat/500.css';
import '@fontsource/montserrat/600.css';
import '@fontsource/montserrat/700.css';
import '@fontsource/montserrat/800.css';
import '@fontsource/montserrat/900.css';

const SectionContainerRoot = styled(Container, {
  name: 'MdlSectionContainer', // The component name
  slot: 'root', // The slot name
})(({ theme, ownerState }) => ({
  display: 'flex',
  flexDirection: 'column',

  [theme.breakpoints.up('xs')]: {
    paddingTop: '3rem',
    paddingBottom: '6rem',
  },
  [theme.breakpoints.up('md')]: {
    paddingTop: '3rem',
    paddingBottom: '12rem',
  },
  backgroundColor: theme.palette.background.normal,
  ...(ownerState.shade==='black' && {
    backgroundColor: 'black',
  }),
  ...(ownerState.shade==='dark' && {
    backgroundColor: theme.palette.background.dark,
  }),
  ...(ownerState.shade==='medium' && {
    backgroundColor: theme.palette.background.medium,
  }),
  ...(ownerState.shade ==='light' && {
    backgroundColor: theme.palette.background.light,
  }),
  ...(ownerState.shade ==='primary' && {
    backgroundColor: theme.palette.background.primary,
  }),
  position: 'relative',
  alignItems: 'center',
  width: '100%',

}));

const SectionContainerTitleBar = styled(Box, {
  name: 'MdlSectionContainer',
  slot: 'titleBar',
})(({ theme }) => ({
  width: { xs: '85%', md: '70%' },
  textAlign: 'center',
}));

const SectionContainerTitleLabel = styled(Typography, {
  name: 'MdlSectionContainer',
  slot: 'titleLabel',
})(({ theme, ownerState }) => ({
  [theme.breakpoints.up('xs')]: {
    paddingBottom: '1.5rem',
  },
  [theme.breakpoints.up('md')]: {
    paddingBottom: '3rem',
  },
  color: theme.palette.text.primary,
  ...(ownerState.shade==='black' && {
    color: theme.palette.primary.contrastText,
  }),
  ...(ownerState.shade==='dark' && {
    color: theme.palette.text.secondary,
  }),
  ...(ownerState.shade==='medium' && {
    color: theme.palette.text.secondary,
  }),
  ...(ownerState.shade ==='light' && {
    color: theme.palette.text.secondary,
  }),
  ...(ownerState.shade ==='primary' && {
    color: theme.palette.primary.contrastText,
  }),
  fontWeight: '800',
  fontFamily: 'Montserrat, sans-serif', 
  textTransform: 'uppercase',
}));

const SectionContainerMessage = styled(Typography, {
  name: 'MdlSectionContainer',
  slot: 'sectionMessage',
})(({ theme, ownerState }) => ({
  [theme.breakpoints.up('xs')]: {
    paddingBottom: '1.5rem',
  },
  [theme.breakpoints.up('md')]: {
    paddingBottom: '3rem',
  },
  color: theme.palette.text.primary,
  ...(ownerState.shade==='dark' && {
    color: theme.palette.text.secondary,
  }),
  ...(ownerState.shade ==='light' && {
    color: theme.palette.text.secondary,
  }),
  ...(ownerState.shade ==='primary' && {
    color: theme.palette.primary.contrastText,
  }),
}));


const SectionContainer = React.forwardRef(function SectionContainer(props, ref) {
  const { id, sectionTitle, sectionMessage, children, ...other } = props;
  const ownerState = { ...props };
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <SectionContainerRoot id={id} maxWidth='100%' ref={ref} ownerState={ownerState} {...other}>
      <SectionContainerTitleBar ownerState={ownerState}>
        {sectionTitle &&
          <SectionContainerTitleLabel
            variant={ isMd ? "h2" : "h4" }
            ownerState={ownerState}
            component={motion.div}
            viewport={{ once: true, amount: 0.25 }}
            initial={{ opacity: 0, y: 25}}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ ease: 'easeOut', duration: 0.4 }}
        >
            {sectionTitle}
          </SectionContainerTitleLabel>
        }
      </SectionContainerTitleBar>
      {sectionMessage &&
        <SectionContainerMessage
          variant="subtitle1"
          ownerState={ownerState}
          component={motion.div}
          viewport={{ once: true, amount: 0.25 }}
          initial={{ opacity: 0, y: 25}}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ ease: 'easeOut', duration: 0.4, delay: 0.1 }}
        >
          {sectionMessage}
        </SectionContainerMessage>
      }
      {children}
    </SectionContainerRoot>
  );
});

export default SectionContainer;
