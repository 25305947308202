import * as React from 'react';
import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import XIcon from '@mui/icons-material/X';
import SectionContainer from '../mdl-components/MdlSectionContainer';
import Carousel from 'react-material-ui-carousel'
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { NavLink } from 'react-router-dom';
import { motion } from "framer-motion"
import { styled } from '@mui/material/styles';
import {Tweet} from 'react-twitter-widgets';
import { eagerLoadTwitterLibrary } from "react-twitter-widgets";

eagerLoadTwitterLibrary();

const tweetList = [
  '1825304709642539094',
  '1822794236573462826',
  '1819862271390355785',
];

var debugCount = 0;

const BlackStyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: 'black',
  color: theme.palette.primary.contrastText,
  marginTop: '3rem',
  ':hover': {
      backgroundColor: 'black',
  },
}));

export default function TwitterCarousel() {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <SectionContainer
      sectionTitle="Logger News"
      shade="dark"
    >
      { isMd &&
        <Carousel
          navButtonsAlwaysVisible
          animation="fade"
          autoPlay={false}
          changeOnFirstRender={true}
          sx={{
            width: { xs: '100%', md: '700px'},
          }}
        >
          {tweetList.map((tweet, index) => (
            <Box
              sx={{
                display: 'block',
                width: { xs: '100%', md: '700px'},
                maxWidth: { xs: '100%', md: '700px'},
                justifyContent: 'center',
              }}
            >
              <Tweet
                tweetId={tweet}
                options={{
                  align: 'center',
                  width: '550',
                }}
              />
            </Box>
          ))}
        </Carousel>
      }
      { !isMd &&
        <Carousel
          navButtonsAlwaysVisible
          animation="fade"
          autoPlay={false}
          fullHeightHover={false}     // We want the nav buttons wrapper to only be as big as the button element is
          navButtonsWrapperProps={{   // Move the buttons to the bottom. Unsetting top here to override default style.
            style: {
              bottom: '0',
              top: 'unset'
            }
          }} 
          indicatorContainerProps={{
            style: {
              marginTop: '25px',
            }
          }}
          sx={{
            width: { xs: '100%', md: '700px'},
            paddingBottom: '40px',
          }}
        >
          {tweetList.map((tweet, index) => (
            <Box
              sx={{
                display: 'block',
                width: { xs: '100%', md: '700px'},
                maxWidth: { xs: '100%', md: '700px'},
                justifyContent: 'center',
              }}
            >
              <Tweet
                tweetId={tweet}
                options={{
                  align: 'center',
                }}
              />
            </Box>
          ))}
        </Carousel>
      }
      <motion.div
        viewport={{ once: true, amount: 0.25 }}
        initial={{ opacity: 0, y: 25}}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ ease: 'easeOut', duration: 0.4, delay: 0.2 }}
      >
        <BlackStyledButton 
          color="secondary"
          variant="contained"
          sx={{ 
            display: 'flex', 
          }}
          href="https://x.com/LoggersBasebal" 
          size='large'
          endIcon={<XIcon sx={{marginTop: '-3px'}}/>}
        >
          Follow us on
        </BlackStyledButton>
      </motion.div>
    </SectionContainer>
  );
}
