import * as React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import SectionContainer from '../mdl-components/MdlSectionContainer';
import MdlContactMethodCard from '../mdl-components/MdlContactMethodCard';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import PhoneIcon from '@mui/icons-material/Phone';
import SmsIcon from '@mui/icons-material/Sms';
import EmailIcon from '@mui/icons-material/Email';
import PageTitle from './PageTitle';
import GoToTop from './GoToTop';
import { motion } from "framer-motion"

import '@fontsource/krona-one';


export default function Contact() {
  return (
    <div>
      <PageTitle title='Contact' />
      <SectionContainer
        id="contact"
        sectionTitle="Contact"
        shade="light"
      >
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Typography
              variant="h6"
              gutterBottom
              component={motion.div}
              viewport={{ once: true, amount: 0.25 }}
              initial={{ opacity: 0, y: 25}}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ ease: 'easeOut', duration: 0.4, delay: 0.1 }}
              sx={{ color: 'text.primary', maxWidth: { xs: '90%', md: '50%' }, textAlign: 'center', fontWeight: '400' }}
            >
            For inquiries about playing townball, scheduling games, or sponsoring our team, please reach out to Jay Haws.
          </Typography>
        </Box>
        <Box
          component={motion.img}
          src="../static/images/jaws.jpg"
          alt="Jay Haws"
          maxWidth= {{ xs:'100%', md:'50%' }}
          viewport={{ once: true, amount: 0.25 }}
          initial={{ opacity: 0, y: 25 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ ease: 'easeOut', duration: 0.4, delay: 0.2 }}
          sx={{
            maxWidth: { xs: '100%', md:'50%'},
            height: 'auto',
          }}
        />
        <Stack
          direction= 'row'
          alignItems='center'
          sx={{
            display: { xs: 'none', md: 'flex' },
            width: { xs: '100%', md: '50%'},
            justifyContent: 'center',
          }}
        >
          <MdlContactMethodCard 
            component={motion.div}
            viewport={{ once: true, amount: 0.25 }}
            initial={{ opacity: 0, x: -25}}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ ease: 'easeOut', duration: 0.4, delay: 0.3 }}
            icon={<PhoneIcon 
            sx={{ fontSize: 24 }} />} 
            title='Phone' 
            description='651-491-8615'
            titleVariant="body2"
            descriptionVariant="body2"
            justifyContent="center"
            url='tel:1-651-491-8615'
            shade='light'
          />
          <Divider
            component={motion.div}
            viewport={{ once: true, amount: 0.25 }}
            initial={{ opacity: 0, y: -25}}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ ease: 'easeOut', duration: 0.4, delay: 0.8 }}
            orientation="vertical"
            variant="middle"
            flexItem 
            sx={{ bgcolor: "primary.main" }}
          />
          <MdlContactMethodCard 
            component={motion.div}
            viewport={{ once: true, amount: 0.25 }}
            initial={{ opacity: 0, x: -25}}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ ease: 'easeOut', duration: 0.4, delay: 0.4 }}
            icon={<SmsIcon 
            sx={{ fontSize: 24 }} />} 
            title='Text' 
            description='651-491-8615' 
            titleVariant="body2"
            descriptionVariant="body2"
            justifyContent="center"
            url='sms:1-651-491-8615'
            shade='light' 
          />
          <Divider
            component={motion.div}
            viewport={{ once: true, amount: 0.25 }}
            initial={{ opacity: 0, y: -25}}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ ease: 'easeOut', duration: 0.4, delay: 0.8 }}
            orientation="vertical"
            variant="middle"
            flexItem 
            sx={{ bgcolor: "primary.main" }}
          />
          <MdlContactMethodCard 
            component={motion.div}
            viewport={{ once: true, amount: 0.25 }}
            initial={{ opacity: 0, x: -25}}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ ease: 'easeOut', duration: 0.4, delay: 0.5 }}
            icon={<EmailIcon 
            sx={{ fontSize: 24 }} />} 
            title='Email' 
            description='jayhaws11bb@gmail.com' 
            titleVariant="body2"
            descriptionVariant="body2"
            justifyContent="center"
            url='mailto:jayhaws11bb@gmail.com'
            shade='light'
          />
        </Stack>
        <Stack
          direction= 'column'
          divider={<Divider variant="middle" sx={{ bgcolor: "primary.contrastText" }}/>}
          sx={{
            display: { xs: 'flex', md: 'none' },
            width: '100%',
            justifyContent: 'center',
          }}
        >
          <MdlContactMethodCard 
            component={motion.div}
            viewport={{ once: true, amount: 0.25 }}
            initial={{ opacity: 0, x: -25}}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ ease: 'easeOut', duration: 0.4, delay: 0.0 }}
            icon={<PhoneIcon sx={{ fontSize: 40 }} />} 
            title='Phone' 
            description='651-491-8615' 
            titleVariant="h6"
            descriptionVariant="h6"
            url='tel:1-651-491-8615'
            shade='light'
          />
          <MdlContactMethodCard 
            component={motion.div}
            viewport={{ once: true, amount: 0.25 }}
            initial={{ opacity: 0, x: -25}}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ ease: 'easeOut', duration: 0.4, delay: 0.0 }}
            icon={<SmsIcon sx={{ fontSize: 40 }} />} 
            title='Text' 
            description='651-491-8615' 
            titleVariant="h6"
            descriptionVariant="h6"
            url='sms:1-651-491-8615'
            shade='light' 
          />
          <MdlContactMethodCard 
            component={motion.div}
            viewport={{ once: true, amount: 0.25 }}
            initial={{ opacity: 0, x: -25}}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ ease: 'easeOut', duration: 0.4, delay: 0.0 }}
            icon={<EmailIcon sx={{ fontSize: 40 }} />} 
            title='Email' 
            description='jayhaws11bb@gmail.com' 
            titleVariant="h6"
            descriptionVariant="h6"
            url='mailto:jayhaws11bb@gmail.com'
            shade='light'
          />
        </Stack>
      </SectionContainer>
      <GoToTop />
    </div>
  );
}
