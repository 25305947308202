import * as React from 'react';
import {useEffect } from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Box from '@mui/material/Box';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import AutoAwesomeRoundedIcon from '@mui/icons-material/AutoAwesomeRounded';
import AppAppBar from './components/AppAppBar';
import Hero from './components/Hero';
import Schedule from './components/Schedule';
import AboutUs from './components/AboutUs';
import Roster from './components/Roster';
import Sponsors from './components/Sponsors';
import Stats from './components/Stats';
import PhotoGallery from './components/PhotoGallery';
import Contact from './components/Contact';
import '@fontsource/roboto/100.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import '@fontsource/roboto/900.css';
import '@fontsource/montserrat/100.css';
import '@fontsource/montserrat/200.css';
import '@fontsource/montserrat/300.css';
import '@fontsource/montserrat/400.css';
import '@fontsource/montserrat/500.css';
import '@fontsource/montserrat/600.css';
import '@fontsource/montserrat/700.css';
import '@fontsource/montserrat/800.css';
import '@fontsource/montserrat/900.css';
import ReactGA from 'react-ga4';


const TRACKING_ID = "G-T9TQHJT31V";

ReactGA.initialize(TRACKING_ID);

function ToggleCustomTheme({ showCustomTheme, toggleCustomTheme }) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100dvw',
        position: 'fixed',
        bottom: 24,
      }}
    >
      <ToggleButtonGroup
        color="primary"
        exclusive
        value={showCustomTheme}
        onChange={toggleCustomTheme}
        aria-label="Platform"
        sx={{
          backgroundColor: 'background.default',
          '& .Mui-selected': {
            pointerEvents: 'none',
          },
        }}
      >
        <ToggleButton value>
          <AutoAwesomeRoundedIcon sx={{ fontSize: '20px', mr: 1 }} />
          Custom theme
        </ToggleButton>
        <ToggleButton value={false}>Material Design 2</ToggleButton>
      </ToggleButtonGroup>
    </Box>
  );
}

ToggleCustomTheme.propTypes = {
  showCustomTheme: PropTypes.shape({
    valueOf: PropTypes.func.isRequired,
  }).isRequired,
  toggleCustomTheme: PropTypes.func.isRequired,
};

const mlTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#D2042D', //Red
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#000000', 
      contrastText: '#ffffff',
    },
    text: {
      primary: '#000000',
      secondary: '#D2042D', //Red,
    },
    background: {
      light: '#eeeeee',
      medium: '#e0e0e0',
      dark: '#bdbdbd',
      white: '#ffffff',
      primary: '#D2042D', //Red
      paper: '#ffffff',
      default: '#ffffff',
    },
    typography: {
      allVariants: {
        fontFamily: 'Montserrat, sans-serif',
        fontWeight: '700',
      },
      h1: {
        fontFamily: 'Montserrat, sans-serif',
        fontWeight: '700',
      },
      h2: {
        fontFamily: 'Montserrat, sans-serif',
        fontWeight: '700',
      },
      h3: {
        fontFamily: 'Montserrat, sans-serif',
        fontWeight: '700',
      },
      h4: {
        fontFamily: 'Montserrat, sans-serif',
        fontWeight: '700',
      },
      h5: {
        fontFamily: 'Montserrat, sans-serif',
        fontWeight: '700',
      },
      h6: {
        fontFamily: 'Montserrat, sans-serif',
        fontWeight: '700',
      },
      subtitle1: {
        fontFamily: 'Roboto, sans-serif',
        fontWeight: '700',
      },
      subtitle2: {
        fontFamily: 'Roboto, sans-serif',
        fontWeight: '700',
      },
      body1: {
        fontFamily: 'Roboto, sans-serif',
      },
      body2: {
        fontFamily: 'Roboto, sans-serif',
      },
      button: {
        fontFamily: 'Montserrat, sans-serif',
        fontWeight: '700',
      },
      caption: {
        fontFamily: 'Roboto, sans-serif',
      },
      overline: {
        fontFamily: 'Roboto, sans-serif',
      },
    },
  },
});

export default function LandingPage() {
  const [mode, setMode] = React.useState('light');


  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: document.title });
  }, []);

  const toggleColorMode = () => {
    setMode((prev) => (prev === 'dark' ? 'light' : 'dark'));
  };

  return (
    <ThemeProvider theme={mlTheme}>
      <Router>
        <AppAppBar mode={mode} toggleColorMode={toggleColorMode} />
        <Box sx={{ pt: { xs: '50px', sm: '60px'} }}>
          <Routes >
            <Route path="/aboutus" element={<AboutUs />} />
            <Route path="/roster" element={<Roster />} />
            <Route path="/schedule" element={<Schedule />} />
            <Route path="/sponsors" element={<Sponsors includePageTitle />} />
            <Route path="/stats" element={<Stats />} />
            <Route path="/photogallery" element={<PhotoGallery />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/" element={<Hero />} />
          </Routes>
        </Box>
      </Router>
    </ThemeProvider>
  );
}
