import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';
import LoggersCarousel from '../components/LoggersCarousel';
import TwitterCarousel from '../components/TwitterCarousel';
import SectionContainer from '../mdl-components/MdlSectionContainer';
import { TwitterTimelineEmbed, TwitterTweetEmbed } from 'react-twitter-embed';
import PageTitle from './PageTitle';
import GoToTop from './GoToTop';
import GameDetails from './GameDetails';
import { getSchedule } from '../data/schedule';
import { NavLink } from 'react-router-dom';
import { motion } from "framer-motion"
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';


//import { Swiper, SwiperSlide } from 'swiper/react';
//import { EffectCoverflow, Navigation, Pagination } from 'swiper/modules';

//import 'swiper/css';
//import 'swiper/css/pagination';
//import 'swiper/css/navigation';

Date.prototype.addDays = function(days) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
}

const schedule = getSchedule();

schedule.sort(function(a,b){
  return new Date(a.date) - new Date(b.date);
});

const nowDate = new Date();
const startDate = nowDate.addDays(-1);
const endDate = nowDate.addDays(7);

const sponsors = [
  {
    imgSrc: "../static/images/StillwaterMotors1.png",
    altText: "Stillwater Motors Logo",
    caption: "Stillwater Motors",
    url: "https://www.cornerstonechevroletstillwater.com/",
  },
  {
    imgSrc: "../static/images/SpineAndSport1.png",
    altText: "Spine and Sport Chiropractic Logo",
    caption: "Spine & Sport Chiropractic",
    url: "https://www.kranzchiropractic.com/",
  },
  {
    imgSrc: "../static/images/Cozzies.png",
    altText: "Cozzie's Logo",
    caption: "Cozzie's Tavern & Grill",
    url: "http://www.cozziestavern.com/",
  },
]

const tweetList = [
  '1825304709642539094',
];

const RedStyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  ':hover': {
    backgroundColor: theme.palette.primary.main,
  },
}));

const MediumGrayStyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.background.dark,
  color: theme.palette.primary.contrastText,
  ':hover': {
      backgroundColor: theme.palette.background.medium,
  },
}));

export default function Hero() {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Box
      id="hero"
      sx={{
        width: '100%',
      }}
    >
      <PageTitle />
      <LoggersCarousel />
      <SectionContainer
        id="hero"
        sectionTitle="Stillwater Loggers"
        shade="light"
      >
        <Stack spacing={2} alignItems='center' useFlexGap sx={{ width: { xs: '100%', sm: '100%' } }}>
          { isMd &&
            <Typography
                textAlign={{ xs:"center", md:"center"}}
                color="text.primary"
                variant="h5"
                component={motion.div}
                initial={{ opacity: 0, y: 25}}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ ease: 'easeOut', duration: 0.4, delay: 0.1 }}
                viewport={{ once: true, amount: 0.25 }}
                sx={{ alignSelf: 'center', width: { xs: '90%', md: '60%' }, fontWeight: '600'}}
              >
              State Tournament Appearances: 1924 (runner-up), 1959, 1560, 2007, 2009, 2022, 2024
              <br /><br />
            </Typography>
          }
          { !isMd &&
            <Typography
                textAlign={{ xs:"center", md:"center"}}
                color="text.primary"
                variant="h6"
                component={motion.div}
                initial={{ opacity: 0, y: 25}}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ ease: 'easeOut', duration: 0.4, delay: 0.1 }}
                viewport={{ once: true, amount: 0.25 }}
                sx={{ alignSelf: 'center', width: { xs: '90%', md: '60%' }, fontWeight: '600'}}
              >
              State Tournament Appearances: 1924 (runner-up), 1959, 1560, 2007, 2009, 2022, 2024
              <br /><br />
            </Typography>
          }
          <Typography
            textAlign={{ xs:"center", md:"justify"}}
            color="text.primary"
            variant="h6"
            component={motion.div}
            initial={{ opacity: 0, y: 25}}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ ease: 'easeOut', duration: 0.4, delay: 0.1 }}
            viewport={{ once: true, amount: 0.25 }}
            sx={{ alignSelf: 'center', width: { xs: '90%', md: '60%' }, fontWeight: '400'}}
          >
            Minnesota Townball, an amateur wood-bat league with a rich history spanning over a century, has been a staple in communities like Stillwater since its inception. Open to players aged 16 and older, the league primarily features local and college talent. The Loggers play their home games at the Stillwater Area High School Varsity Field and are members of the Skyline League.
            <br /><br />
          </Typography>
          <motion.div
            viewport={{ once: true, amount: 0.25 }}
            initial={{ opacity: 0, y: 25}}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ ease: 'easeOut', duration: 0.4, delay: 0.2 }}
          >
            <RedStyledButton 
              color="secondary"
              component={NavLink}
              variant="contained"
              sx={{ display: 'flex' }}
              to='/aboutus'
              size='large'
            >
              Read more
            </RedStyledButton>
          </motion.div>
        </Stack>

{/*
        <TwitterTimelineEmbed
          sourceType="profile"
          screenName="LoggersBasebal"
          options={{
            height: 400,
            width: 400,
            tweetLimit: 1,
          }}
        />*/}
      </SectionContainer>

{/*
      <SectionContainer
        sectionTitle="Upcoming Game"
        shade="medium"
      >
        {
          schedule.map((gameDetail, index) => (
              (gameDetail.date >= startDate && gameDetail.date <= endDate) &&
              <GameDetails gameDetail={gameDetail}/>
            )
          )
        }
      </SectionContainer>
*/}

{/*
      <SectionContainer
        sectionTitle="State Tournament Bracket"
        shade="primary"
      >
        <Box
          component="img"
          src="../static/images/mba_state_2024.jpeg"
          alt="Schedule"
          maxWidth= {{ xs:'100%', md:'75%' }}
          sx={{
            borderRadius: 3,
          }}
        />
      </SectionContainer>
*/}
        <TwitterCarousel />

{/*
        <SectionContainer
          sectionTitle="Logger News"
          shade="dark"
        >
          <Box
            sx={{
              width: { xs: '90%', md: '60%' },
              maxWidth: '550px',
              justifyContent: 'center',
            }}
          >
            {tweetList.map((tweet, index) => (
              <Box
                sx={{
                  paddingBottom: 3,
                }}
              >
                <TwitterTweetEmbed
                  tweetId={tweet}
                  option={{
                    align: 'center',
                    width: 550,
                  }}
                />
              </Box>
            ))}
          </Box>
          <motion.div
            viewport={{ once: true, amount: 0.25 }}
            initial={{ opacity: 0, y: 25}}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ ease: 'easeOut', duration: 0.4, delay: 0.2 }}
          >
            <BlackStyledButton 
                color="secondary"
                component={NavLink}
                variant="contained"
                sx={{ display: 'flex' }}
                to='/aboutus'
                size='large'
              >
                Follow on
            </BlackStyledButton>
          </motion.div>
        </SectionContainer>
*/}
        <SectionContainer
          id="sponsors"
          sectionTitle="Our Sponsors"
          shade="primary"
        >
          <Box sx={{ width: { xs: '90%', md: '70%' } }}>
            <Grid container spacing={2} justifyContent="center" columns={{xs: 1, md: 3}}>
              {sponsors.map((sponsor, index) => (

                <Grid item xs={1} >
                  <motion.div
                    viewport={{ once: true, amount: 0.25 }}
                    initial={{ opacity: 0, x: -25}}
                    whileInView={{ opacity: 1, x: 0 }}
                    transition={{ ease: 'easeOut', duration: 0.4, delay: 0.1*(index + 1) }}
                  >
                    <Card 
                      elevation='4'
                      sx={{ 
                        m: '4',
                        display: 'flex', 
                        justifyContent: 'center',
                      }}
                    >
                      <CardActionArea href={sponsor.url} sx={{justifyContent: 'center', display: 'flex'}}>
                        <img
                          src={sponsor.imgSrc}
                          alt={sponsor.altText}
                          style={{
                            maxWidth: '100%',
                            height: '150px',
                          }}
                        />
                      </CardActionArea>
                    </Card>
                  </motion.div>
                </Grid>
              ))}
            </Grid>
          </Box>
          { isMd &&
            <Typography
                variant="h4"
                gutterBottom
                sx={{ color: 'primary.contrastText', maxWidth: { xs: '90%', md: '60%' }, textAlign: 'justify', fontWeight: 400, pt: 5 }}
                component={motion.div}
                viewport={{ once: true, amount: 0.25 }}
                initial={{ opacity: 0, y: 25}}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ ease: 'easeOut', duration: 0.4, delay: 0.1 }}
              >
                Interested in supporting Loggers baseball?
                <br/> <br /> 
            </Typography>
          }
          { !isMd &&
            <Typography
                variant="h5"
                gutterBottom
                sx={{ color: 'primary.contrastText', maxWidth: { xs: '90%', md: '60%' }, textAlign: 'center', fontWeight: 400, pt: 5 }}
                component={motion.div}
                viewport={{ once: true, amount: 0.25 }}
                initial={{ opacity: 0, y: 25}}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ ease: 'easeOut', duration: 0.4, delay: 0.1 }}
              >
                Interested in supporting Loggers baseball?
                <br/> <br /> 
            </Typography>
          }
          <motion.div
            viewport={{ once: true, amount: 0.25 }}
            initial={{ opacity: 0, y: 25}}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ ease: 'easeOut', duration: 0.4, delay: 0.2 }}
          >
            <MediumGrayStyledButton 
              color="secondary"
              component={NavLink}
              variant="contained"
              sx={{ display: 'flex' }}
              to='/contact'
              size='large'
            >
              Contact Us
            </MediumGrayStyledButton>
          </motion.div>
        </SectionContainer>

{/*     <Timeline
        dataSource={{
          sourceType: 'profile',
          screenName: 'LoggersBasebal'
        }}
        options={{
          height: 600,
          dataTweetLimit: 1,
        }}
        sx={{
          width: { sm: '90%', md: '60%' }
        }}
       />*/}
{/*      <SectionContainer
        shade="black"
      >
        <Typography
          textAlign="center"
          color="secondary.contrastText"
          variant="h6"
          component={motion.div}
          initial={{ opacity: 0, y: 25}}
          animate={{ opacity: 1, y: 0 }}
          transition={{ ease: 'easeOut', duration: 0.4 }}
          sx={{ alignSelf: 'center', width: { xs: '90%', md: '70%' } }}
        >
          For the latest updates regarding games and news about the Loggers, please visit our{' '}
          <Link href="https://twitter.com/LoggersBasebal" target="_blank" color="secondary.contrastText">
            X page
          </Link>.
        </Typography>
      </SectionContainer>*/}
      <GoToTop />
    </Box>
  );
}

/*
            Minnesota Townball, an amateur wood-bat league with a rich history spanning over a century, has been a staple in communities like Stillwater since its inception. Open to players aged 16 and older, the league primarily features local talent, although many college athletes also bring in their friends to bolster team rosters. Unlike collegiate baseball, Townball is not driven by regimented processes. Instead, the best players demonstrate their skills and natural talent on the field. Those accustomed to structured practice routines must independently maintain their training during the summer months. In Stillwater, numerous local athletes take advantage of the high school's field and facilities during the summer to hone their hitting and overall performance.
            <br /><br />
            The Stillwater Loggers adopted their name in 1955, following a remarkable 1954 season where Bud Grant led the pitching staff with a 14-1 record. As of 2025, the Loggers will celebrate 70 years under this name. They play their home games at the Stillwater High School Varsity Field. The Loggers have been integral members of the Skyline League since its inception 20 years ago. The majority of the Loggers' roster consists of graduates from Stillwater High School.
            <br /><br />
            Jay Haws currently serves as the owner and manager of the team. He has been coaching the Loggers since 2022 and has been an integral part of the team since graduating in 2019. Haws briefly played college football before transferring to the University of Minnesota, where he joined the club team. Several players have been recruited from this club team. As of 2024, the Loggers' roster consists of 36 active players, many of whom were members of the Stillwater teams that participated in the MSHSL state championships in 2018 and 2022.
*/
/*
Stillwater Loggers&nbsp;
<Typography
  component="span"
  textAlign="justify"
  color="text.primary"
  variant="subtitle1"
  sx={{fontWeight: '400'}}
>
  is a Minnesota Townball team poised to celebrate its 70th anniversary in 2025. Minnesota Townball is a century-old amateur wood-bat league, known for showcasing local and college talent. The team, managed by Jay Haws, plays at the Stillwater High School Varsity Field and is a part of the Skyline League. The Loggers’ roster, primarily composed of Stillwater High School graduates, includes 36 active players as of 2024, many of whom have participated in the MSHSL state championships. Find out more about us on our&nbsp;
  <NavLink to="/aboutus" style={{ textDecoration: 'none' }}>
    <Typography
      component="span"
      textAlign="justify"
      color="text.primary"
      variant="subtitle1"
      sx={{fontWeight: '400', textDecoration: 'underline'}}
    >
      History
    </Typography>
  </NavLink>
  &nbsp;page.
</Typography>
*/

//<blockquote class="twitter-tweet"><p lang="en" dir="ltr">Game today at the high school to host Metro Knights at 4. Hoping to get one for league play 🪵🪓 <a href="https://t.co/fLB8zx5IEd">pic.twitter.com/fLB8zx5IEd</a></p>&mdash; Stillwater Loggers (@LoggersBasebal) <a href="https://twitter.com/LoggersBasebal/status/1812502628120543393?ref_src=twsrc%5Etfw">July 14, 2024</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>

//The Loggers is a Minnesota Townball team established in 1955. Townball is an amateur wood bat league known for showcasing local and college talent. The team plays at Stillwater Area High School Varsity Field and is part of the Skyline League. Learn more on our&nbsp;
