import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const PageTitle = ({ title }) => {
  const location = useLocation();

  useEffect(() => {
    if (title != null) {
      document.title = 'Stillwater Loggers Baseball- ' + title;
    } else {
      document.title = 'Stillwater Loggers Baseball';
    } 
  }, [location, title]);

  return null;
};

export default PageTitle;