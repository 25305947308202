import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import Divider from '@mui/material/Divider';
import Avatar from '@mui/material/Avatar';
import Stack from'@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { styled, } from '@mui/material/styles';
import { motion } from "framer-motion"

const ContactMethodCardRoot = styled(Card, {
  name: 'MdlContactMethodCard', // The component name
  slot: 'root', // The slot name
})(({ theme, ownerState }) => ({
  display: 'flex',
  flexDirection: 'column',

  [theme.breakpoints.up('xs')]: {
    paddingTop: '1rem',
    paddingBottom: '1rem',
  },
  [theme.breakpoints.up('md')]: {
    paddingTop: '1rem',
    paddingBottom: '1rem',
  },
  backgroundColor: theme.palette.background.normal,
  ...(ownerState.shade==='dark' && {
    backgroundColor: theme.palette.background.dark,
  }),
  ...(ownerState.shade ==='light' && {
    backgroundColor: theme.palette.background.light,
  }),
  ...(ownerState.shade ==='primary' && {
    backgroundColor: theme.palette.background.primary,
  }),
  position: 'relative',
  alignItems: 'center',
  width: '100%',

}));

const ContactMethodCardAvatar = styled(Box, {
  name: 'MdlContactMethodCard', // The component name
  slot: 'avatar', // The slot name
})(({ theme, ownerState }) => ({
  color: theme.palette.text.primary,
  ...(ownerState.shade==='dark' && {
    color: theme.palette.text.primary,
  }),
  ...(ownerState.shade ==='light' && {
    color: theme.palette.text.secondary,
  }),
  ...(ownerState.shade ==='primary' && {
    color: theme.palette.primary.contrastText,
  alignContent: 'center',
  }),
  alignContent: 'center',
}));


const ContactMethodCardTitle = styled(Typography, {
  name: 'MdlContactMethodCard', // The component name
  slot: 'title',
})(({ theme, ownerState }) => ({
  color: theme.palette.text.primary,
  ...(ownerState.shade==='dark' && {
    color: theme.palette.text.primary,
  }),
  ...(ownerState.shade ==='light' && {
    color: theme.palette.text.secondary,
  }),
  ...(ownerState.shade ==='primary' && {
    color: theme.palette.primary.contrastText,
  }),
  fontWeight: 'bold',
  alignContent: 'center',
  textAlign: 'left'
}));

const ContactMethodCardDescription = styled(Typography, {
  name: 'MdlContactMethodCard', // The component name
  slot: 'description',
})(({ theme, ownerState }) => ({
  color: theme.palette.text.primary,
  ...(ownerState.shade==='dark' && {
    color: theme.palette.text.primary,
  }),
  ...(ownerState.shade ==='light' && {
    color: theme.palette.text.secondary,
  }),
  ...(ownerState.shade ==='primary' && {
    color: theme.palette.primary.contrastText,
  }),
  fontWeight: '300',
  alignContent: 'center',
  textAlign: 'left'
}));

const MdlContactMethodCard = React.forwardRef(function MdlContactMethodCard(props, ref) {
  const { icon, title, titleVariant, description, descriptionVariant, justifyContent, url, ...other } = props;
  const ownerState = { ...props };

  return (
    <ContactMethodCardRoot
      maxWidth='xl'
      elevation="0"
      ref={ref}
      ownerState={ownerState} 
      {...other}
    >
      <CardActionArea
        href={url} 
        sx={{
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <Stack
          direction= 'row'
          spacing={1}
          alignItems='center'
          justifyContent={justifyContent}
          width= '100%'
        >
          <ContactMethodCardAvatar variant="rounded" ownerState={ownerState}>
            {icon}
          </ContactMethodCardAvatar >
          <ContactMethodCardTitle variant={titleVariant} ownerState={ownerState}>
            {title}
          </ContactMethodCardTitle>
          <ContactMethodCardDescription variant={descriptionVariant} ownerState={ownerState}>
            {description}
          </ContactMethodCardDescription>
        </Stack>
      </CardActionArea>
    </ContactMethodCardRoot>
  );
});

export default MdlContactMethodCard;
