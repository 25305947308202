import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import SectionContainer from '../mdl-components/MdlSectionContainer';
import PageTitle from './PageTitle';
import GoToTop from './GoToTop';
import { motion } from "framer-motion"


import '@fontsource/poppins';
import '@fontsource/montserrat';

const historyItems = [
  {
    title: "History of Townball in Stillwater",
    image: {
      src: "../static/images/history/Loggers1950.jpg",
      alt: "Old Loggers Team Photo",
    },
    description: [
      "The town of Stillwater has a long-standing tradition of amateur baseball, dating back to the 1800s. America's pastime garnered significant local support as Minnesota's \"Townball\" gained popularity in the early 20th century. By 1924, the inaugural Townball state tournament concluded with Sartell narrowly defeating the Stillwater team in the championship game by a score of 6 to 5.",
      "Following their initial state championship appearance, Stillwater experienced a 35-year hiatus from the state tournament, not returning until 1959. They made another appearance in 1960 but did not compete again until 2007. Subsequent appearances were made in 2009 and most recently in 2022, under the current management of Jay Haws.",
    ],
  },
  {
    title: "1954 Season",
    image: {
      src: "../static/images/history/Loggers1954.jpg",
      alt: "Loggers in 1954",
    },
    description: [
      "In 1954, the town team acquired Minnesota legend Bud Grant as a pitcher. At the time, Bud was excelling in three sports at the University of Minnesota and held roster spots with both Stillwater and the Osceola Braves. During an era when the top players in the state were typically paid $50-75 per game, Bud negotiated a unique arrangement with Stillwater, requesting they \"double that, and only pay [him] when Stillwater won.\" Under his leadership, Stillwater achieved an impressive 14-1 record that year, narrowly missing the state tournament but garnering a significant local following.",
      "Following the conclusion of the season, the Stillwater Gazette hosted a contest to name the town team. With hundreds of entries submitted, three residents emerged as winners, sharing the $50 prize. Thus, the Stillwater Loggers were born.",
    ],
  },
  {
    title: "Management",
    image: {
      src: "../static/images/history/LoggersCamp.jpg",
      alt: "Loggers Kid's Camp",
    },
    description: [
      "During the early 2000s, the Stillwater Loggers faced significant challenges within their section, consistently losing to the St. Croix Valley Diamondcutters, both in games and key players. The Diamondcutters remained a formidable opponent until their dissolution in 2012. The Loggers managed to surpass them only twice, under the leadership of two different managers: Scott Johnson in 2007 and his son Robbie Johnson in 2009.",
      "By 2013, Robbie Johnson's team began to lose momentum, with diminishing player attendance resulting in several forfeited games. Consequently, per league regulations, the team was deemed ineligible for the playoffs. Robbie decided to step down, and resident Dave Andrews assumed ownership for the remainder of the season.",
      "Dave Andrews managed the Loggers for the next eight full seasons. However, like his predecessors, he struggled to retain a sufficient number of players to maintain competitiveness. Following a particularly difficult season in 2021, during which the team secured only three victories, player Jay Haws took over ownership. Jay has been at the helm ever since, striving to revitalize the team.",
    ],
  },
  {
    title: "2022",
    image: {
      src: "../static/images/history/Loggers2022.jpg",
      alt: "Loggers 2022",
    },
    description: [
      "In Haws’ inaugural season as the Loggers’ player-coach, he signed 12 new players, retaining only six from previous teams. The new recruits initially faced challenges, with the Loggers failing to score a run until their third game and securing their first victory in their fifth game. However, a timely out-of-town tournament in Elrosa proved pivotal, helping to unify the team. Upon their return, the Loggers won five of their next six games, concluding June on a high note. By the end of July, they had achieved a positive record of 12-9, securing the second seed in their league ahead of the state tournament. Unfortunately, their season ended in the first round of the state playoffs with a narrow 3-2 loss to Highland Park. Despite holding a 2-1 lead until the bottom of the eighth inning, the Loggers were unable to maintain their advantage.",
    ],
  },
  {
    title: "2023",
    image: {
      src: "../static/images/history/Loggers2023.jpg",
      alt: "Loggers 2023",
    },
    description: [
      "In his second season, Haws recruited 14 new players, transforming the roster and moving away from the previous challenges of struggling to field a full team. In the season opener, the Loggers, with 18 players, decisively defeated the reigning State Champions, the St. Louis Park Pirates, by a ten-run margin. Their confidence carried over into the second game, where they triumphed over the team that had eliminated them from the state tournament the previous year. However, this momentum proved difficult to maintain, and they subsequently lost three consecutive games. The season oscillated between remarkable victories and disappointing losses. Ultimately, the team achieved a final record of 22-16, marking their first 20-win season in many years. Despite their efforts, they were eliminated in a grueling three-game series on the final Saturday, falling short of qualifying for the state tournament.",
    ],
  },
  {
    title: "2024",
    image: {
      src: "../static/images/history/Loggers2024.jpg",
      alt: "Loggers 2024",
    },
    description: [
      "With the addition of only five new players, the Loggers solidified their roster and entered the season with renewed confidence. However, their home field is scheduled for renovation in mid-June to improve drainage, resulting in a reduced number of home games. This situation raises concerns about attendance, particularly for the veteran players who experienced similar challenges during the Andrews era.",
      "To be continued...",
    ],
  },
]

const StyledTimelineItem = styled(TimelineItem)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row-reverse', // Start with row-reverse for alternate alignment
  '&:nth-of-type(even)': {
    flexDirection: 'row', // Switch to row for even items to revert order
  },
}));

const StyledImageBox = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '400px', // Set a consistent height for the image container
  overflow: 'hidden', // Hide any overflow
});

const StyledImage = styled('img')({
  width: '100%',
  height: '100%',
  objectFit: 'cover', // Ensure the image covers the container while maintaining aspect ratio
  display: 'block',
  margin: '0 auto',
});

const StyledStepperImage = styled('img')({
  maxWidth: '100%',
  objectFit: 'cover', // Ensure the image covers the container while maintaining aspect ratio
  display: 'block',
  margin: '0 auto',
});

const AboutUs = () => {
  return (
    <Box
      id="aboutus"
      sx={{
        width: '100%',
        backgroundSize: '100% 40%',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <PageTitle title="Our History" />
      <SectionContainer
        id="history"
        sectionTitle="Our History"
        shade="light"
      >

        <Stepper orientation="vertical" sx={{display: {xs:'flex', md: 'none'}}}>
          {historyItems.map((item, index) => (
            <Step key={item.title} active={true} expanded={true}>
              <StepLabel icon={<Avatar sx={{ width: 24, height: 24 }}>&nbsp;</Avatar>} >
                <motion.div
                  viewport={{ once: true, amount: 0.25 }}
                  initial={{ opacity: 0, x: -25}}
                  whileInView={{ opacity: 1, x: 0 }}
                  transition={{ ease: 'easeOut', duration: 0.4 }}
                >
                  <Typography variant="h6" color="primary" sx={{ ml: 1, textAlign: 'left' }}>
                    {item.title}
                  </Typography>
                </motion.div>
              </StepLabel>
              <StepContent>
                <motion.div
                  viewport={{ once: true, amount: 0.25 }}
                  initial={{ opacity: 0, y: 25}}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ ease: 'easeOut', duration: 0.4 }}
                >
                  <StyledStepperImage
                    src={item.image.src}
                    alt={item.image.alt}
                  />
                </motion.div>
                {item.description.map((descItem, index) => (
                  <motion.div
                    viewport={{ once: true, amount: 0.25 }}
                    initial={{ opacity: 0, y: 25}}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ ease: 'easeOut', duration: 0.4 }}
                  >
                    <Typography color="text.primary" sx={{ textAlign: 'left' }}>
                      <br />
                      {descItem}
                    </Typography>
                  </motion.div>
                ))}
              </StepContent>
            </Step>
          ))}
        </Stepper>

        <Timeline position="alternate" sx={{display: {xs:'none', md: 'flex'}}}>
          {historyItems.map((item, index) => (
            <StyledTimelineItem>
              <TimelineOppositeContent>
                <motion.div
                  viewport={{ once: true, amount: 0.25 }}
                  initial={{ opacity: 0, x: (index % 2)===0 ? 25 : -25 }}
                  whileInView={{ opacity: 1, x: 0 }}
                  transition={{ ease: 'easeOut', duration: 0.4, delay: 0.1 }}
                >
                  <StyledImageBox>
                      <StyledImage
                        src={item.image.src}
                        alt={item.image.alt}
                      />
                  </StyledImageBox>
                </motion.div>
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <motion.div
                  viewport={{ once: true, amount: 0.25 }}
                  initial={{ opacity: 0, x: (index % 2)===0 ? -25 : 25 }}
                  whileInView={{ opacity: 1, x: 0 }}
                  transition={{ ease: 'easeOut', duration: 0.4 }}
                >
                  <Typography variant="h5" color="primary" sx={{ textAlign: 'left' }}>
                    {item.title}
                  </Typography>
                </motion.div>
                {item.description.map((descItem, dindex) => (
                <motion.div
                  viewport={{ once: true, amount: 0.25 }}
                  initial={{ opacity: 0, x: (index % 2)===0 ? -25 : 25 }}
                  whileInView={{ opacity: 1, x: 0 }}
                  transition={{ ease: 'easeOut', duration: 0.4, delay: 0.2 + dindex * 0.1 }}
                >
                  <Typography color="text.primary" sx={{ textAlign: 'left' }}>
                    {dindex > 0 && <br />}
                    {dindex > 0 && <br />}
                    {descItem}
                  </Typography>
                </motion.div>
                ))}
              </TimelineContent>
            </StyledTimelineItem>
          ))}
        </Timeline>
      </SectionContainer>
      <GoToTop />
    </Box>
  );
};

export default AboutUs;
