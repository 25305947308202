import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import XIcon from '@mui/icons-material/X';
import MlSvgIcon from '../MlSvgIcon';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';

function Copyright() {
  return (
    <Box sx={{textAlign: { xs: 'center', md: 'left'} }}>
    <Typography variant="body2" color="text.secondary" sx={{ textAlign: { xs: 'center', md: 'left'}, display: 'inline-flex', alignItems: 'center' }}>
      {'©' + new Date().getFullYear() + ' Stillwater Loggers | Site by' }
      <Link href="https://michaellundconsulting.com" target="_blank" sx={{  display: 'inline-flex', alignItems: 'center', ml: 1 }}>
        <MlSvgIcon sx={{ display: 'inline-block', height: 20, color: 'text.secondary', fontSize: { xs: 16, md: 16 } }} />
      </Link>
    </Typography>
    </Box>
  );
}

export default function Footer() {
  return (
    <Container id="footer"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: { sm: 'center', md: 'left' },
        pb: 2,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          pt: { xs: 4, sm: 8 },
          width: '100%',
          borderColor: 'divider',
        }}
      >
        <Stack spacing={5} sx={{width: '100%'}}>
          <Stack
            direction="row"
            justifyContent="center"
            spacing={1}
            useFlexGap
            sx={{
              color: '#D2042D',
            }}
          >
            <IconButton
              sx={{ color: '#D2042D', alignSelf: 'center' }}
              href="https://www.facebook.com/LoggersBaseball/"
              aria-label="Facebook"
            >
              <FacebookOutlinedIcon sx={{width: 38, height: 38}}/>
            </IconButton>
            <IconButton
              sx={{ color: '#D2042D', alignSelf: 'center' }}
              href="https://x.com/LoggersBasebal" 
              aria-label="X"
            >
              <Avatar sx={{ bgcolor: '#D2042D', width: 32, height: 32 }}>
                <XIcon />
              </Avatar>
            </IconButton>
          </Stack>
          <Copyright />
        </Stack>
      </Box>
    </Container>
  );
}
