import * as React from 'react';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import SectionContainer from '../mdl-components/MdlSectionContainer';
import PageTitle from './PageTitle';
import GoToTop from './GoToTop';
import { motion } from "framer-motion"

const itemData = [
  { img: 'LoggersImage1.jpg', title: 'Image 1' },
  { img: 'LoggersImage2.jpg', title: 'Image 2' },
  { img: 'LoggersImage3.jpg', title: 'Image 3' },
  { img: 'LoggersImage4.jpg', title: 'Image 4' },
  { img: 'LoggersImage5.jpg', title: 'Image 5' },
  { img: 'LoggersImage6.jpg', title: 'Image 6' },
  { img: 'LoggersImage7.jpg', title: 'Image 7' },
  { img: 'LoggersImage8.jpg', title: 'Image 8' },
  { img: 'LoggersImage9.jpg', title: 'Image 9' },
  { img: 'LoggersImage10.jpg', title: 'Image 10' },
  { img: 'LoggersImage11.jpg', title: 'Image 11' },
  { img: 'LoggersImage12.jpg', title: 'Image 12' },
  { img: 'LoggersImage13.jpg', title: 'Image 13' },
  { img: 'LoggersImage14.jpg', title: 'Image 14' },
  { img: 'LoggersImage15.jpg', title: 'Image 15' },
  { img: 'LoggersImage16.jpg', title: 'Image 16' },
  { img: 'LoggersImage17.jpg', title: 'Image 17' },
  { img: 'LoggersImage18.jpg', title: 'Image 18' },
  { img: 'LoggersImage19.jpg', title: 'Image 19' },
  { img: 'LoggersImage20.jpg', title: 'Image 20' },
  { img: 'LoggersImage21.jpg', title: 'Image 21' },
];

export default function PhotoGallery() {
  return (
    <Box
      id="photogallery"
    >
      <PageTitle title="Photo Gallery" />
      <SectionContainer
        id="gallery"
        sectionTitle="Photo Gallery"
        shade="light"
      >
        <ImageList sx={{ display: {xs: 'none', md: 'grid'}, width: '80%' , height: 'auto' }} cols={3} gap={8}>
          {itemData.map((item, index) => (
            <ImageListItem key={index}>
              <Box
                src={'../static/images/gallery/thumb_lg/' + item.img}
                alt={item.title}
                loading="lazy"
                style={{ width: '100%', height: 'auto' }}
                component={motion.img}
                viewport={{ once: true, amount: 0.25 }}
                initial={{ opacity: 0, x: -25 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ ease: 'easeOut', duration: 0.4, delay: (index % 3) * 0.1 }}
              />
            </ImageListItem>
          ))}
        </ImageList>
        <ImageList sx={{ display: {xs: 'grid', md: 'none'}, width: '100%', height: 'auto' }} cols={1} gap={8}>
          {itemData.map((item, index) => (
              <Box
                src={'../static/images/gallery/thumb_sm/' + item.img}
                alt={item.title}
                loading="lazy"
                sx={{ width: '100%', height: 'auto' }}
                component={motion.img}
                viewport={{ once: true, amount: 1.0 }}
                initial={{ opacity: 0, y: 25 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ ease: 'easeOut', duration: 0.4 }}
              />
          ))}
        </ImageList>
      </SectionContainer>
      <GoToTop />
    </Box>
  );
}
