import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function LoggersSvgIcon(props) {
  return (
    <SvgIcon {...props} >
      <svg 
        viewBox="0 0 302.7 551.11"
        fill="currentColor"
        strokeWidth={0}
      >
        <path d="M230.58,405.56c-.52,22.5-5.35,40.79-9.69,53.22-10.96-7.35-24.27-14.96-39.99-21.53-37.71-15.77-65.17-10.07-95.35-11.42h-14.71c-1.93,0-3.48-1.56-3.48-3.48v-35.66c0-1.92,1.55-3.48,3.48-3.48h10.36v1.29h6.95v-.76c0-.29-.24-.53-.53-.53h-6.42v-57.43H30.04c-16.4,0-29.69-13.3-29.69-29.7v-17.86h-.35v-22.24c0-2.63,2.13-4.76,4.76-4.76h38.67c2.52,0,4.57,2.04,4.57,4.56v12.09c0,5.33,4.32,9.65,9.65,9.65h23.55v-81.7h48v81.7h23.23c5.33,0,9.65-4.32,9.65-9.65v-71.44c0-5.33-4.32-9.65-9.65-9.65H49.18v.22h-18.28c-16.42,0-29.72-13.31-29.72-29.73V29.67C1.18,13.28,14.46,0,30.85,0h149.63c16.42,0,29.74,13.31,29.74,29.73v18.57h-.14v21.71c0,2.54-2.06,4.6-4.6,4.6h-38.85c-2.52,0-4.55-2.04-4.55-4.55v-12.11c0-5.33-4.32-9.65-9.65-9.65H58.83c-5.33,0-9.65,4.32-9.65,9.65v71.18c0,5.33,4.32,9.65,9.65,9.65h22.37v-52.7c0-3.29,2.66-5.95,5.95-5.95h36.1c3.29,0,5.95,2.66,5.95,5.95v52.7h51.17c16.41,0,29.71,13.3,29.71,29.7v127.58c0,16.41-13.31,29.72-29.72,29.72h-51.16v54.62l2.04-.14c15.31-2.69,32.14-7.19,49.66-14.51,15.72-6.58,29.03-14.18,39.99-21.53,4.69,13.43,10.31,34.79,9.69,61.34Z"/><path class="cls-1" d="M278.55,550.35v.76h-6.95v-1.26c.2-.02.39-.03.59-.03h5.83c.29,0,.53.24.53.53Z"/>
      </svg>
    </SvgIcon>
  );
}

