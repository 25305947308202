import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import SectionContainer from '../mdl-components/MdlSectionContainer';
import PageTitle from './PageTitle';
import GoToTop from './GoToTop';

const roster = [
  { id: 1, jersey: 1, first: 'Brayden', last: 'Hellum', college: 'University of Minnesota', highSchool: "Stillwater" },
  { id: 2, jersey: 2, first: 'Matthew', last: 'McGinley', college: 'DMACC', highSchool: "Stillwater" },
  { id: 3, jersey: 3, first: 'Mason', last: 'Schwerzler', college: 'N/A', highSchool: "Stillwater" },
  { id: 4, jersey: 3, first: 'Owen', last: 'Maslowski', college: 'UW Parkside', highSchool: "Stillwater" },
  { id: 5, jersey: 4, first: 'Alex', last: 'Boston', college: 'University of Minnesota - Duluth', highSchool: "Stillwater" },
  { id: 6, jersey: 5, first: 'Mason', last: 'Buck', college: 'St Olaf', highSchool: "Stillwater" },
  { id: 7, jersey: 6, first: 'Sammy', last: 'Johnson', college: 'Bethel', highSchool: 'St Croix Prep' },
  { id: 8, jersey: 7, first: 'Elijah', last: 'Stifter', college: 'Montreat (NAIA)', highSchool: 'Lake Elmo' },
  { id: 9, jersey: 8, first: 'Matthew', last: 'Kulesa', college: 'St Olaf', highSchool: 'De La Salle' },
  { id: 10, jersey: 9, first: 'Riley', last: 'Findorff', college: 'University of Minnesota (Club)', highSchool: 'Woodbury' },
  { id: 11, jersey: 10, first: 'Cody', last: 'Venske', college: 'St Scholastica', highSchool: "Stillwater" },
  { id: 12, jersey: 11, first: 'Jay', last: 'Haws', college: 'University of Minnesota (Club)', highSchool: "Stillwater" },
  { id: 13, jersey: 12, first: 'Patrick', last: 'Lundgren', college: 'TBD', highSchool: "Stillwater" },
  { id: 14, jersey: 13, first: 'Ben', last: 'Baiel', college: 'University of Minnesota (Club)', highSchool: 'Rosemount' },
  { id: 15, jersey: 14, first: 'Luke', last: 'Cullen', college: 'N/A', highSchool: "Stillwater" },
  { id: 16, jersey: 15, first: 'Wes', last: 'Brand', college: 'Bethel', highSchool: 'East Ridge' },
  { id: 17, jersey: 16, first: 'Austin', last: 'Buck', college: 'UW Eau Claire', highSchool: "Stillwater" },
  { id: 18, jersey: 17, first: 'Ian', last: 'Hanlon', college: 'University of Minnesota (Club)', highSchool: "Stillwater" },
  { id: 19, jersey: 19, first: 'Nolan', last: 'Sobczak', college: 'N/A', highSchool: "Stillwater" },
  { id: 20, jersey: 20, first: 'Cameron', last: 'Hunter', college: 'N/A', highSchool: 'East Ridge' },
  { id: 21, jersey: 21, first: 'Logan', last: 'Jordan', college: 'University of Georgia', highSchool: "Stillwater" },
  { id: 22, jersey: 22, first: 'Mac', last: 'Wolden', college: 'North Dakota State (Club)', highSchool: '' },
  { id: 23, jersey: 23, first: 'DJ', last: 'Andrews', college: 'Winona State (Club)', highSchool: "Stillwater" },
  { id: 24, jersey: 24, first: 'Charley', last: 'Rowan', college: 'UW Lacrosse', highSchool: "Stillwater" },
  { id: 25, jersey: 24, first: 'Jake', last: 'Slipka', college: 'St Johns University', highSchool: 'Centennial' },
  { id: 26, jersey: 26, first: 'Hayden', last: 'Sperbeck', college: 'Bethel', highSchool: 'Mounds View' },
  { id: 27, jersey: 27, first: 'Parker', last: 'Gilchrist', college: 'UMN Morris', highSchool: "Stillwater" },
  { id: 28, jersey: 28, first: 'David', last: 'Scheil', college: 'St Olaf', highSchool: 'Woodbury' },
  { id: 29, jersey: 29, first: 'Dylan', last: 'Ploen', college: 'N/A', highSchool: 'Lino Lakes' },
  { id: 30, jersey: 30, first: 'Rudy', last: 'Roderiguez', college: 'University of Minnesota (Club)', highSchool: 'Minneapolis' },
  { id: 31, jersey: 31, first: 'Tyler', last: 'Chapin', college: 'University of Minnesota (Club)', highSchool: 'Minneapolis' },
  { id: 32, jersey: 32, first: 'Jakob', last: 'Flaten', college: 'Century College', highSchool: 'St Croix Prep' },
  { id: 33, jersey: 34, first: 'Tanner', last: 'Voight', college: 'UW Superior', highSchool: "Stillwater" },
  { id: 34, jersey: 37, first: 'Josh', last: 'Wallace', college: 'University of Nebraska (Club)', highSchool: "Stillwater" },
  { id: 35, jersey: 38, first: 'Jacob', last: 'Marx', college: 'Valley City State (NAIA)', highSchool: 'Champlin Park' },
  { id: 36, jersey: 40, first: 'Mitchell', last: 'Novak', college: 'St Scholastica', highSchool: "Stillwater" },
  { id: 37, jersey: 44, first: 'William', last: 'Silton', college: 'St Olaf', highSchool: 'St Paul' },
];

const columns = [
  { field: 'jersey', headerName: 'Jersey #', width: 120 },
  { field: 'first', headerName: 'First', width: 150 },
  { field: 'last', headerName: 'Last', width: 150 },
  { field: 'college', headerName: 'College', width: 250 },
  { field: 'highSchool', headerName: 'High School/Hometown', width: 300 },
];

const theme = createTheme({
  palette: {
    background: {
      default: '#000000', 
      light: '#f5f5f5',
    },
    text: {
      secondary: '#D2042D', 
    },
  },
  typography: {
    fontFamily: 'Arial, sans-serif',
  },
  components: {
    MuiDataGrid: {
      styleOverrides: {
        root: {
          color: '#000000',
          border: 'none',
          '& .MuiDataGrid-cell': {
            borderBottom: '1px solid rgba(255, 255, 255, 0.12)',
          },
          '& .MuiDataGrid-columnHeaders': {
            color: '#ffffff',
          },
          '& .MuiDataGrid-footerContainer': {
            backgroundColor: '#D2042D',
            color: '#ffffff', 
            '& .MuiTablePagination-root': {
              color: '#ffffff', 
            },
            '& .MuiTablePagination-actions': {
              color: '#ffffff', 
            },
            '& .MuiSvgIcon-root': {
              color: '#ffffff', 
            },
          },
        },
      },
    },
  },
});

export default function Roster() {
  return (
    <ThemeProvider theme={theme}>
      <PageTitle title='2024 Roster' />
      <SectionContainer
        id="roster"
        sectionTitle="2024 Roster"
        shade="light"
      >
        <Box sx={{ height: '100%', width: { xs: '100%', md: '80%' } }}>
          <DataGrid 
            rows={roster} 
            columns={columns} 
            pageSize={10} 
          />
        </Box>
      </SectionContainer>
      <GoToTop />
    </ThemeProvider>
  );
}
