import * as React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import LoggersSvgIcon from '../LoggersSvgIcon';
import { motion } from "framer-motion"

import { styled } from '@mui/material/styles';

import '@fontsource/montserrat/100.css';
import '@fontsource/montserrat/300.css';
import '@fontsource/montserrat/400.css';
import '@fontsource/montserrat/500.css';
import '@fontsource/montserrat/600.css';
import '@fontsource/montserrat/700.css';
import '@fontsource/montserrat/800.css';
import '@fontsource/montserrat/900.css';

const menuItems = [
  {
    to: '/',
    title: 'Home',
  },
  {
    to: '/aboutus',
    title: 'History',
  },
  {
    to: '/schedule',
    title: 'Schedule',
  },
  {
    to: '/roster',
    title: 'Roster',
  },
  {
    to: '/stats',
    title: 'Stats',
  },
  {
    to: '/sponsors',
    title: 'Sponsors',
  },
  {
    to: '/photogallery',
    title: 'Photos'
  },
  {
    to: '/contact',
    title: 'Contact',
  },
];

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.primary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
      },
    },
  },
}));

const StyledMenuItem = styled((props) => (
  <MenuItem
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiMenuItem-root': {
    '&:hover': {
    },
  },
  '& .MuiTypography-root': {
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },

}));

function AppAppBar({ mode, toggleColorMode }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedPage, setSelectedPage] = React.useState(0);

  const handleSelectedPage = (index) => {
    setSelectedPage(index);
  }
  const handleClearSelectedPage = () => {
    setSelectedPage(0);
  }

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <AppBar
        position="fixed"
        top="0"
        sx={{
          bgcolor: 'background.white',
          boxShadow: 0,
          backgroundImage: 'none',
        }}
      >
        <Container maxWidth="xl">
          <Box
            sx={{
              flexGrow: 1,
              display: 'flex',
              alignItems: 'center',
              pl: { xs: 0, md: 6 },
              pr: { xs: 0, md: 6 },
              height: { xs: '50px', md: '60px' },
            }}
          >
            <NavLink to="/" style={{ textDecoration: 'none' }} onClick={() => {handleClearSelectedPage();}}>
              <LoggersSvgIcon color="primary" sx={{ fontSize: { xs: 40, md: 48 }, mt: 1, mr: { xs: 0, md: 1}, display: 'flex' }}/>
            </NavLink>
            <NavLink to="/" style={{ textDecoration: 'none' }} onClick={() => {handleClearSelectedPage();}}>
              <Box>
                <Typography
                  variant="h6"
                  noWrap
                  component="h6"
                  color="secondary"
                  sx={{
                    display: { xs: 'none', md: 'flex' },
                    fontWeight: 800,
                    textDecoration: 'none',
                    fontFamily: 'Montserrat, sans-serif', 
                  }}
                >
                  STILLWATER
                  <Typography
                    variant="h6"
                    noWrap
                    component="span"
                    color="secondary"
                    sx={{
                      display: { xs: 'none', md: 'block' },
                      fontWeight: 800,
                      textDecoration: 'none',
                      fontFamily: 'Montserrat, sans-serif', 
                    }}
                  >
                    &nbsp;LOGGERS&nbsp;
                  </Typography>
                </Typography>
                <Typography
                  variant="subtitle2"
                  noWrap
                  component="h6"
                  color="secondary"
                  sx={{
                    display: { xs: 'flex', md: 'none' },
                    fontWeight: 800,
                    textDecoration: 'none',
                    fontFamily: 'Montserrat, sans-serif', 
                  }}
                >
                  STILLWATER
                  <Typography
                    variant="subtitle2"
                    noWrap
                    component="span"
                    color="secondary"
                    sx={{
                      display: { xs: 'block', md: 'none' },
                      fontWeight: 800,
                      textDecoration: 'none',
                      fontFamily: 'Montserrat, sans-serif', 
                    }}
                  >
                    &nbsp;LOGGERS&nbsp;
                  </Typography>
                </Typography>
              </Box>
            </NavLink>
            <Box sx={{ display: { xs: 'none', md: 'flex', marginLeft: 'auto' } }}>
              {menuItems.map((item, index) => (

                <motion.div
                  initial={{ opacity: 0, x: -25}}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ ease: 'easeOut', duration: 0.4, delay: index*0.1 }}
                >
                  <motion.div
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.95 }}
                  >


                    <StyledMenuItem
                      key={index}
                      component={NavLink}
                      to={item.to}
                      sx={{ py: '6px', px: '12px' }}
                      target="_self"
                      onClick={() => {handleSelectedPage(index);}}
                    >
                        <Typography
                        variant="button"
                        color={selectedPage === index ? "primary" : "secondary"}
                        fontFamily= "Montserrat, sans-serif" 
                        fontWeight="600"
                      >
                        {item.title}
                      </Typography>
                    </StyledMenuItem>

                  </motion.div>
                </motion.div>


              ))}
            </Box>
            <IconButton
              id="menu-id"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="secondary"
              sx={{ display: { xs: 'flex', md: 'none', marginLeft: 'auto' } }}
            >
              {Boolean(anchorEl) ? <CloseIcon sx={{ fontSize: 32 }} /> : <MenuIcon sx={{ fontSize: 32 }} />}
            </IconButton>
            <StyledMenu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              sx={{ display: { xs: 'flex', md: 'none' } }}
            >
              {menuItems.map((item, index) => (
                <MenuItem
                  key={index}
                  component={NavLink}
                  to={item.to}
                  onClick={() => handleClose()}
                  target="_self"
                >
                  <Typography width="540px" align="center" variant="button" fontFamily= "Montserrat, sans-serif" fontWeight="bold">
                    {item.title}
                  </Typography>
                </MenuItem>
              ))}
            </StyledMenu>
          </Box>
        </Container>
      </AppBar>
    </div>
  );
}

AppAppBar.propTypes = {
  mode: PropTypes.oneOf(['dark', 'light']).isRequired,
  toggleColorMode: PropTypes.func.isRequired,
};

export default AppAppBar;
